import React from "react"
import doug from "../../assets/DouggyDo.jpg"

class NotFound extends React.Component {
    render(){
        return(
            <div>
                <h1>I'm Sowwy. I coodn't fwind dat pwage</h1>
                <img src={doug} alt={"not found"} className={"not-found-logo"}></img>
            </div>
        )
    }
}

export default NotFound