import React, { Component } from "react";

class ProjectsPage extends Component {
    render() {
        return (
            <div className={"main-body"}>

            </div>
        )
    }
}

export default ProjectsPage