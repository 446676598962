import React from "react";
import PropTypes from "prop-types";
import Navbar from "./Navbar";
import "./Navigation.css"
import { Link } from 'react-router-dom'



const Navigation = ( {activePage, user, signOut} ) => {
    return (
        <header>
            <div className={"nav-area"}>
                <Link to={"/"} className={"logo"}> logo </Link>
                <Navbar activePage={activePage}/>
                <div className={"username"}>{user.username} | </div>
                <button onClick={signOut}> Sign Out</button>
            </div>
        </header>
    )
}

Navigation.propTypes = {
    activePage: PropTypes.string.isRequired
}
export default Navigation;