import { useState, useEffect, useRef } from 'react';
import Dropdown from './Dropdown';

import { Link } from 'react-router-dom';

export const menuItems = [
    {
        title: 'Home',
        url: '/',
    },
    {
        title: 'About',
        url: '/about',
        submenu: [
            {
                title: 'Portfolio',
                url: 'portfolio',
            },
            {
                title: 'About Me',
                url: 'about',
            },
            {
                title: 'Bio',
                url: 'bio',
            },
        ],
    },
    {
        title: 'Projects',
        url: '/projects',
        submenu: [
            {
                title: 'MyAnimeRecommender',
                url: 'myanimerecommender',
            },
            {
                title: 'OzNet',
                url: 'oz',
            },
            {
                title: 'MPCNC',
                url: 'mpcnc',
            },

        ],
    },
    {
        title: 'Notes',
        url: '/notes',
    },
    {
        title: 'Blog',
        url: '/blog',
    },
    {
        title: 'Tools',
        url: '/tools',
        submenu: [
            {
                title: 'Portfolio',
                url: 'portfolio',
            },
        ],
    },
];



const MenuItems = ({ items, depthLevel, activePage }) => {
    const [dropdown, setDropdown] = useState(false);

    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (
                dropdown &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    return (
        <li
            className={ items.title===activePage ? 'active menu-items' : 'menu-items'}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={closeDropdown}

        >
            {items.url && items.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {window.innerWidth < 960 && depthLevel === 0 ? (
                            items.title
                        ) : (
                            <Link to={items.url}>{items.title}</Link>
                        )}

                        {depthLevel > 0 &&
                        window.innerWidth < 960 ? null : depthLevel > 0 &&
                        window.innerWidth > 960 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : !items.url && items.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{' '}
                        {depthLevel > 0 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                <Link to={items.url}>{items.title}</Link>
            )}
        </li>
    );
};

export default MenuItems;