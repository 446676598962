import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import HomePage from "./pages/HomePage/HomePage";
import NotFound from "./components/NotFound/NotFound";
import ProjectsPage from "./pages/ProjectPage/ProjectPage";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import App from "./components/App/App";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <Routes>
            <Route exact path={"/"} element={<App children={<HomePage/>} activePage={"Home"}/>}/>
            <Route path={"*"} element={<App children={<NotFound/>} activePage={"NotFound"}/>}/>
            <Route path = {"/projects"} element={<App children={<ProjectsPage/>} activePage={"projects"}/>}/>
        </Routes>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
