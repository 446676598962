import styled from 'styled-components';

export const Box = styled.div`
  padding: 80px 0px;
  background: #3d3b3b;
  position: relative;
  bottom: 0;
  width: 100%;


  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 0 auto;
    margin-bottom: -40px;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,
                            minmax(250px, 1fr));
    grid-gap: 10px;
    margin-left: 100px;
    margin-right: 100px;
    
    //@media (max-width: 1000px) {
    //    grid-template-columns: repeat(auto-fill,
    //                        minmax(200px, 1fr));
    //}
`;

export const FooterLink = styled.a`
    color: #fff;
    margin-bottom: 20px;
    font-size: 15px;
    text-decoration: none;
    
    &:hover {
        color: #e13d0c;
        transition: 200ms ease-in;
    }
`;

export const Heading = styled.p`
    font-size: 20px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: bold;
`;
