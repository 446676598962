import React from "react";
import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading
} from "./FooterStyles";


const Footer = () => {
    return (
        <Box>
            <h1 style={{
                color: "#e13d0c",
                textAlign: "center",
                marginTop: "-50px"
            }}>
                OddBlog: A Blog For The Odd
            </h1>
            <Container>
                <Row>
                    <Column>
                        <Heading>About Me</Heading>
                        <FooterLink href={"/"}>About Me</FooterLink>
                    </Column>
                    <Column>
                        <Heading>Contact Me</Heading>
                        <FooterLink href={"/"}>Email Form</FooterLink>
                        <FooterLink href={"/"}>Discord</FooterLink>
                        <FooterLink href={"/"}>GitHub Repo</FooterLink>
                        <FooterLink href={"/"}>Linked In</FooterLink>
                    </Column>
                    <Column>
                        <Heading>Links</Heading>
                        <FooterLink href={"/"}>GitHub</FooterLink>
                        <FooterLink href={"/"}>HackerOne</FooterLink>
                        <FooterLink href={"/"}>Projects</FooterLink>
                    </Column>
                </Row>
            </Container>
        </Box>
    )
}

export default Footer