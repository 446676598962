import React, { Component } from "react";
import logo from "../../assets/OddMan.JPG"

class HomePage extends Component {
    render() {
        return (
            <div>
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <h1>
                        OddBlog
                    </h1>
                    <p>Home of the odd</p>
                </header>
            </div>
        )
    }
}

export default HomePage