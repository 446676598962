import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import PropTypes from "prop-types";
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


const App = ({ signOut, user, children, activePage }) => {
    return (
        <div className={"App"}>
            <Navigation activePage={activePage} user={user} signOut={signOut} />
            {children}
            <Footer/>
        </div>
    )
}
App.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element.isRequired
    ]),
    activePage: PropTypes.string.isRequired
}

export default withAuthenticator(App,
    {
        hideSignUp: true
    });